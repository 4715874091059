<template>
    <q-card flat>
        <q-item>
            <q-item-section avatar>
                <q-avatar>
                    <q-icon class="glossy" color="primary" size="30px" name="fas fa-link" />
                </q-avatar>
            </q-item-section>
            <q-item-section>
                <q-item-label>外部链接</q-item-label>
            </q-item-section>
            <q-item-section side>
                <q-btn dense class="q-mr-md" color="primary" icon="note_add" @click="onAdd">新增</q-btn>
            </q-item-section>
        </q-item>

        <q-separator />

        <q-card-section>
            <vxe-table border stripe resizable highlight-hover-row :max-height="$store.getters.maxPageHeight - 146"
                :loading="TgyBaseLinkListLoading" :data="TgyBaseLinkList" size="mini">
                <vxe-table-column type="seq" title="#" width="50" align="center" />
                <vxe-table-column title="链接名称" field="LinkName" />
                <vxe-table-column title="链接地址" field="LinkAddress" />
                <vxe-table-column title="操作" width="300px" fixed="right">
                    <template #default="{ row }">
                        <q-btn size="sm" color="primary" flat @click="onEdit(row)" icon="fas fa-edit">编辑</q-btn>
                        <q-btn size="sm" color="negative" flat @click="onDelete(row)" icon="fas fa-minus-square">删除</q-btn>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </q-card-section>
    </q-card>
    <vxe-pager perfect size="mini" :page-sizes="tablePage.pageSizes" v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize" :total="tablePage.totalResult" :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
        ]" @page-change="handlePageChange">
    </vxe-pager>

    <q-dialog v-model="TgyBaseLinkshowDlg" persistent>
        <LinkEdit @onLoadData="onLoadData" />
    </q-dialog>

</template>

<script>
import { mapActions, mapState } from "vuex";
import LinkEdit from "./baseLinkEdit"

export default {
    components: {
        LinkEdit,
    },
    computed: {
        ...mapState("TgyBaseLink", [
            "TgyBaseLinkList",
            "TgyBaseLinkListLoading",
            "TgyBaseLinkEntity",
            "TgyBaseLinkShowDlg",
        ]),

        TgyBaseLinkshowDlg: {
            get() {
                return this.TgyBaseLinkShowDlg;
            },
            set(val) {
                console.log(val);
                this.$store.commit("TgyBaseLink/setTgyBaseLinkShowDlg", val);
            },
        },
    },
    data() {
        return {
            FilterString: "",
            tablePage: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0,
            },
        };
    },
    mounted() {
        this.onLoadData();
    },
    methods: {
        ...mapActions("TgyBaseLink", [
            "actGetTgyBaseLinkListByPage",
            "actCreateTgyBaseLink",
            "actEditTgyBaseLinkData",
            "actEditTgyBaseLink",
            "actDeleteTgyBaseLink",
        ]),
        onLoadData() {
            this.actGetTgyBaseLinkListByPage({
                HospID: this.$store.getters["appUser/hospID"],
                CUser: this.$store.getters["appUser/userID"],
                FilterString: this.FilterString,
                PageIndex: this.tablePage.currentPage,
                pageSize: this.tablePage.pageSize,
            }).then(res => {
                this.tablePage.totalResult = res.Count;
            });
        },
        onAdd() {
            this.actCreateTgyBaseLink({
                HospID: this.$store.getters["appUser/hospID"],
                CUser: this.$store.getters["appUser/userID"],
            });
        },
        onEdit(e) {
            var obj = {};
            Object.assign(obj, e);
            this.actEditTgyBaseLink(obj);
        },
        onDelete(e) {
            this.actDeleteTgyBaseLink({
                HospID: this.$store.getters["appUser/hospID"],
                CUser: this.$store.getters["appUser/userID"],
                Data: e,
            }).then((res) => {
                if (res.Code == 0) {
                    this.onLoadData();
                }
            });
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage;
            this.tablePage.pageSize = pageSize;
            this.onLoadData();
        }
    },
};
</script>
